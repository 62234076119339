export const useRecurringConsent = () => {
    const consentCode = useCookie<string | undefined>('recurring-consent:consentCode', {
        sameSite: 'lax',
        secure: true,
    })

    return {
        consentCode
    }
}
